export { default as Splide } from "@splidejs/splide";

export const fr = {
  prev: "Diapo précédente",
  next: "Diapo suivante",
  first: "Aller à la première diapo",
  last: "Aller à la dernière diapo",
  slideX: "Aller à diapo %s",
  pageX: "Aller à la page %s",
  slide: "diapo",
  slideLabel: "%s sur %s",
  select: "Choisir une diapo à afficher",
  play: "Lancer le défilement automatique",
  pause: "Pauser le défilement automatique",
  carousel: "diaporama",
};
